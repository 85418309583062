<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：实时库存明细</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="2">
                                    <el-checkbox style=" margin-top: 12px; " v-model="formData.mergeWarehouse" @change="findDetailWarehouse">合并仓库</el-checkbox>
                            </el-col>
                            <el-col :md="3" >
                                <el-button style=" margin-top: 8px; " class="pull-left" type="primary" round  @click="findDetailWarehouse" size="mini">搜索查询</el-button>
                                <!--跳转时表格问题，需要  @submit.native.prevent-->
                                <vxe-toolbar ref="xToolbar2" style=" height: 42px; " custom export >
                                </vxe-toolbar>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="detailWarehouseXGrid"
                                id="detailWarehouseXGrid"
                                size="mini"
                                align="right"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-hover-row
                                highlight-current-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :row-class-name="tableRowClassName"
                                :custom-config="{storage:true}"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>


                    </el-col>


                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 2000
               },
                tableColumn: [
                    {
                        field:'category', width: 100, title: '类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'specification_name', width: 160, title: '规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'single_count', width: 100, title: '支数(可用量)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'single_lock', width: 100, title: '支数(锁定)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'single_count_total', width: 100, title: '支数(总库存)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'warehouse_name', width: 600, title: '仓库', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'a_side',visible:false, width: 100, title: 'A边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'b_side',visible:false, width: 100, title: 'B边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'thickness', visible:false,width: 100, title: '厚度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                        }
                   }
                    , {
                        field:'length_extent', visible:false,width: 100, title: '长度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                        }
                   }, {
                        field:'theoretical_weight', visible:false,width: 100, title: '理论重量', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouse/searchHeard', this.pickerDate)
                        }
                   }
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {
                    'specification_name' : 'desc',
                    'warehouse_name' : 'asc'
                },
                start_date: '',
                end_date: '',
                specification: [],
                formData: {
                    mergeWarehouse:false
               }
           }
       },
        methods: {
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.findDetailWarehouse()
           },
            findDetailWarehouse(){
                this.$axios({
                    method: 'POST',
                    url: '/admin/detailWarehouse',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                        pickerDate:this.pickerDate,
                        formData: this.formData
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.list
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
       },
        created() {
            this.$nextTick(() => {
                    // 手动将表格和工具栏进行关联
                this.$refs.detailWarehouseXGrid.connect(this.$refs.xToolbar2)
                this.findDetailWarehouse();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
